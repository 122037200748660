<template>
    <div>
        <select-shop :loadingButton="loadingButton" v-on:getData="getDailyReport"></select-shop>
        <div v-if="isPermission">
            <CCard class="shadow-sm">
                <CCardBody>
                    <div class="row">
                        <div class="col-md-10 col-8 col-sm-10 d-inline-flex align-items-center">
                            <h2 class="mb-0 font-weight-normal">
                                Delivery
                            </h2>
                        </div>
                        <div class="col-md-2 col-4 col-sm-3 text-right text-success">

                        </div>
                    </div>
                    <CRow>
                        <CCol sm="12" lg="12">
                            <show-date></show-date>
                            <hr />
                            <CRow>
                                <CCol lg="3">
                                    <CWidgetProgressIcon color="success" inverse :progress="{ value: 75 }"
                                        text="Totals Order" header="12.124">
                                        <!-- <CIcon name="cil-people" height="32"/> -->
                                    </CWidgetProgressIcon>
                                </CCol>
                                <CCol lg="3">
                                    <CWidgetProgressIcon color="primary" inverse :progress="{ value: 75 }"
                                        text="Totals Sales" header="12.124">
                                        <!-- <CIcon name="cil-people" height="32"/> -->
                                    </CWidgetProgressIcon>
                                </CCol>
                                <CCol lg="3">
                                    <CWidgetProgressIcon color="info" inverse :progress="{ value: 75 }"
                                        text=" Net Sales" header="12.124">
                                        <!-- <CIcon name="cil-people" height="32"/> -->
                                    </CWidgetProgressIcon>
                                </CCol>
                                <CCol lg="3">
                                    <CWidgetProgressIcon color="danger" inverse :progress="{ value: 75 }"
                                        text="Cancelled Orders" header="12.124">
                                        <!-- <CIcon name="cil-people" height="32"/> -->
                                    </CWidgetProgressIcon>
                                </CCol>
                            </CRow>
                            <br />
                            <br />

                            <CRow>
                                <CCol lg="7">
                                    <CCard style="box-shadow: 0 0 0 1px #e5e5e5;" >
                                        <CCardBody>
                                            <h5>Delivery Channels</h5>
                                            <CRow>
                                                <CCol lg="5">
                                                    <ul class="list-group">
                                                        <li class="list-group-item " style="    border: 0px solid; padding: 4.5px;"
                                                            v-for="(list, index) in salesChannel"
                                                            :key="index">
                                                            <CCallout color="info" style="margin: 0.0rem 0;box-shadow: 0 0 0 1px #e5e5e5;">

                                                                <div class="row" style="padding: 5px;">
                                                                    <div class="col-2">
                                                                        <img :src="list.img" class="img-fluid rounded"
                                                                            width="30px" />
                                                                    </div>
                                                                    <div class="col text-left">
                                                                        <strong>
                                                                            {{ $t(list.name) }}
                                                                        </strong>
                                                                        <p>No of Orders: 50 • Amount: 100</p>

                                                                    </div>
                                                                </div>
                                                            </CCallout>
                                                        </li>
                                                    </ul>
                                                </CCol>
                                                <CCol lg="7" style=" align-items: center;">
                                                    <CChartDoughnut :datasets="deliveryChart.datasets"
                                                        :labels="deliveryChart.chartLabels"
                                                        :options="deliveryChart.chartOptions"
                                                        style="height: 300px;align-items: center;" />
                                                </CCol>
                                            </CRow>
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                                <CCol lg="5">
                                    <CCard style="box-shadow: 0 0 0 1px #e5e5e5;">
                                        <CCardBody>


                                            <h5>Hourly Sales Roport</h5>
                                            <CChartBar :datasets="chartBar.datasets" :labels="chartBar.labels"
                                                :options="chartBar.chartOptions" style="height: 365px; width: 100%;" />
                                        </CCardBody>
                                    </CCard>
                                </CCol>
                            </CRow>
                            <div style="overflow-x:auto;">
                                <DataTable style="white-space: nowrap;" :items="items()" :fields="fields"
                                    :loading="loading" hover border clickableRows @row-clicked="rowClicked">
                                    <template #discountAmount="{ item }">
                                        <td v-if="item.discountAmount > 0.00" class="text-right text-danger">-{{
                                            item.discountAmount }}</td>
                                        <td v-else class="text-right text-dark">{{ item.discountAmount }}</td>
                                    </template>
                                </DataTable>
                            </div>
                        </CCol>
                    </CRow>
                    <pagination :meta_data="meta_data" v-on:next="getDailyReport">
                    </pagination>
                </CCardBody>
            </CCard>
        </div>
        <div v-else>
            <access-data></access-data>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import axios from '@/services/service'
import report from '@/services/report'
import pos from "@/services/local";
import Pagination from '@/containers/Pagination'
import util from '@/util/util'
import DataTable from '@/containers/DataTable'
import permis from '@/util/permission'
import { CWidgetProgressIcon } from "@coreui/vue"
import { CChartDoughnut, CChartBar } from "@coreui/vue-chartjs";
import { getColor, hexToRgba } from "@coreui/utils/src";

export default {
    components: {
        Pagination,
        DataTable,
        CWidgetProgressIcon,
        CChartDoughnut,
        CChartBar
    },
    data() {
        return {
            data: [],
            meta_data: {
                last_page: null,
                current_page: 1,
                prev_page_url: null,
                items: 0,
                limit: null,
                itemCount: null,
            },
            loadingButton: true,
            salesChannel: [],
        }
    },
    mounted() {
        this.addImageLabels();
    },
    computed: {
        ...mapGetters(['shops', 'date', 'users']),
        chartBar() {
            const brandWarning = getColor("warning") || "#E99537";
            const brandInfo = getColor("info") || "#4CB2D4";
            const brandSuccess = getColor("success") || "#4dbd74";
            const brandDanger = getColor("danger") || "#F29089";

            return {
                // Filters
                // selectedCountry: "Country",
                // selectedCity: "Cities",
                // Chart labels (time slots)
                labels: ["Grab", "Food Panda", "Robin Hood", "Customer Pickup", "Store Delivery"],
                // Chart datasets
                datasets: [
                    {
                        label: "News",
                        backgroundColor: hexToRgba(brandWarning, 80),
                        pointHoverBackgroundColor: brandWarning,
                        borderWidth: 2,
                        borderColor: brandWarning,
                        lineTension: 0,
                        data: [10, 8, 20, 4, 5, 10, 32], // Sales data for each hour
                    },
                    {
                        label: "Preparing",
                        backgroundColor: hexToRgba(brandInfo, 80),
                        pointHoverBackgroundColor: brandInfo,
                        borderWidth: 2,
                        borderColor: brandInfo,
                        lineTension: 0,
                        data: [2, 18, 13, 14, 40, 10, 10], // Sales data for each hour
                    },
                    {
                        label: "Completed",
                        backgroundColor: hexToRgba(brandSuccess, 80),
                        pointHoverBackgroundColor: brandSuccess,
                        borderWidth: 2,
                        borderColor: brandSuccess,
                        lineTension: 0,
                        data: [25, 48, 23, 14, 50, 60, 30], // Sales data for each hour
                    },
                    {
                        label: "Cancel",
                        backgroundColor: hexToRgba(brandDanger, 80),
                        pointHoverBackgroundColor: brandDanger,
                        borderWidth: 2,
                        borderColor: brandDanger,
                        lineTension: 0,
                        data: [2, 8, 3, 4, 18, 16, 10], // Sales data for each hour
                    },

                ],
                // Chart options
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    scales: {
                        x: {
                            grid: {
                                display: false,
                            },
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                        y: {
                            grid: {
                                display: true,
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            display: false, // Hide default legend
                        },
                    },
                    
                },
                
            }
        },

        deliveryChart() {
            return {
                // Chart labels
                chartLabels: ["Grab", "Wongnai", "Food Panda", "Robin Hood", "Customer Pickup", "Store Delivery"],
                // Orders and amounts
                orders: [20, 10, 15, 15, 25, 30],
                amounts: [340, 100, 220, 220, 280, 350],
                // Dataset configuration
                datasets: [
                    {
                        label: "Totals",
                        backgroundColor: ["#28a745", "#fd7e14", "#dc3545", "#007bff", "#e83e8c", "#ffc107"],
                        data: [20, 10, 15, 15, 25, 30], // Inner ring values
                    },
                    // {
                    //     label: "Amount",
                    //     backgroundColor: ["#7ee081", "#ffae55", "#f27b8b", "#56a3f2", "#f990c0", "#ffe47a"],
                    //     data: [340, 100, 220, 220, 280, 350], // Outer ring values
                    // },
                ],
                colors: ["#28a745", "#fd7e14", "#dc3545", "#007bff", "#e83e8c", "#ffc107"], // Colors for legend
                chartOptions: {
                    legend: { position: "right" },
                    responsive: true,
                    height: 200,
                    maintainAspectRatio: false,
                    cutoutPercentage: 50, // Doughnut hole size
                    plugins: {
                        legend: {
                            display: false, // Hide default legend
                        },
                        tooltip: {
                            callbacks: {
                                label: function (tooltipItem) {
                                    return `${tooltipItem.label}: ${tooltipItem.raw}`;
                                },
                            },
                        },
                    },
                },
            };
        },
        isPermission() {
            // return permis.findPermissionRead('report', this.$route.path)
            return true
        },
        isExport() {
            return permis.findPermissionExport('report', this.$route.path)
        },
        uid() {
            return `${localStorage.getItem('shopsUid')}`
        },
        shopObjectId: {
            get() {
                return this.$store.getters.shopObjectId
            },
            set(newValue) {
                return this.$store.dispatch('setShop', newValue)
            },
        },
        startDate() {
            return moment(String(this.date.start)).format('DD MMMM YYYY')
        },
        endDate() {
            return moment(String(this.date.end)).format('DD MMMM YYYY')
        },
        fields() {
            return [
                {
                    key: 'date',
                    label: this.$i18n.t('date'),
                    _classes: 'text-left text-dark font-weight-normal ',
                },
                {
                    key: 'netAmount',
                    label: this.$i18n.t('netAmount'),
                    _classes: 'text-right text-dark font-weight-normal ',
                },
                {
                    key: 'discountAmount',
                    label: this.$i18n.t('discount'),
                    _classes: 'text-right text-dark font-weight-normal',
                },
                {
                    key: 'vatAmount',
                    label: this.$i18n.t('vatAmount'),
                    _classes: 'text-right text-dark font-weight-normal',
                },
                {
                    key: 'serviceCharge',
                    label: this.$i18n.t('serviceCharge'),
                    _classes: 'text-right text-dark font-weight-normal',
                },
                {
                    key: 'netProfit',
                    label: this.$i18n.t('profit'),
                    _classes: 'text-right text-dark font-weight-normal',
                },
                {
                    key: 'grandTotal',
                    label: this.$i18n.t('grandTotal'),
                    _classes: 'text-right text-dark font-weight-normal',
                },
            ]
        },

    },
    created() {
        this.getSalesChannelsSetting()
        this.getDailyReport()
    },
    methods: {
        ...util,
        items() {
            let data = this.data
            let detail = []
            let grandTotal = ''
            let discountTotal = 0
            let vatAmount = ''
            let netAmount = ''
            let serviceCharge = ''

            for (let i = 0; i < data.length; i++) {
                if (data[i].grandTotal === undefined) {
                    grandTotal = '0.00'
                } else {
                    grandTotal = util.convertCurrency(data[i].grandTotal)
                }

                let discount = data[i].discountTotal
                if (discount === undefined) {
                    discountTotal = 0
                } else {
                    discountTotal = util.convertCurrency(data[i].discountTotal)
                }

                if (data[i].vatAmount === undefined) {
                    vatAmount = '0.00'
                } else {
                    vatAmount = util.convertCurrency(data[i].vatAmount)
                }

                if (data[i].netAmount === undefined) {
                    netAmount = '0.00'
                } else {
                    netAmount = util.convertCurrency(data[i].netAmount)
                }

                if (data[i].serviceCharge === undefined) {
                    serviceCharge = '0.00'
                } else {
                    serviceCharge = util.convertCurrency(data[i].serviceCharge)
                }
                let netProfit = 0
                if (data[i].list !== undefined && Array.isArray(data[i].list)) {
                    data[i].list.forEach(item => {
                        if (item.netProfit !== undefined) {
                            netProfit += item.netProfit
                        }
                    })
                    netProfit = util.convertCurrency(netProfit)
                } else {
                    netProfit = '0.00'
                }

                detail.push({
                    date: moment(String(data[i].date)).format('DD/MM/YYYY'),
                    dateString: data[i].date,
                    grandTotal: grandTotal,
                    discountAmount: discountTotal,
                    vatAmount: vatAmount,
                    serviceCharge: serviceCharge,
                    netAmount: netAmount,
                    netProfit: netProfit
                })
            }
            return detail
        },
        rowClicked(item) {
            this.$router.push(
                '/report/daily/' + this.shopObjectId + '/' + item.dateString
            )
        },
        getDailyReport(page = 1) {
            this.loadingButton = false
            const uid = this.uid
            const shopObjectId = this.shopObjectId

            const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
            const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

            const params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt: endAt,
                page: page,
                limit: 50,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            this.loading = true
            axios({
                url: '/receipt/v1.0/productselldailygroupreport/' + uid + '/data',
                params: params,
                headers: headers,
                method: 'GET',
            })
                .then((res) => {
                    this.data = res.data.data.list
                    if (res.data.paginate.items == undefined) {
                        this.meta_data.items = 0
                    } else {
                        this.meta_data.items = res.data.paginate.items
                    }
                    this.meta_data.last_page = res.data.paginate.pageCount
                    this.meta_data.current_page = res.data.paginate.currentPage
                    this.meta_data.itemCount = res.data.paginate.itemCount
                    this.meta_data.limit = res.data.paginate.perPage
                    // this.meta_data.prev_page_url = res.data.paginate.currentPage - 1
                    this.loading = false
                    this.loadingButton = true
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        exportDailyReport() {
            const uid = this.uid
            const shopObjectId = this.shopObjectId

            const startAt = moment(String(this.date.start)).format('YYYY-MM-DD')
            const endAt = moment(String(this.date.end)).format('YYYY-MM-DD')

            const params = {
                shopObjectId: shopObjectId,
                startAt: startAt,
                endAt: endAt,
            }
            const headers = {
                shopObjectId: shopObjectId,
            }
            report({
                url: '/receipt/v1.0/productselldailyreport/' + uid + '/excel',
                params: params,
                method: 'GET',
                headers: headers,
                responseType: 'blob', // important
            })
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]))
                    const link = document.createElement('a')
                    link.href = url
                    link.setAttribute('download', 'รายงานยอดขายสินค้าประจำวัน.xlsx') //or any other extension
                    document.body.appendChild(link)
                    link.click()
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        getSalesChannelsSetting() {
            let params = {
                shopObjectId: this.shopObjectId,
                type: 'ONLINE'
            }
            pos({
                url: "/api/v3.0/sellingchannel/list",
                params: params,
                method: "get",
            }).then((res) => {
                let data = res.data.data;
                let detail = [];
                for (let i = 0; i < data.length; i++) {
                    detail.push({
                        objectId: data[i].objectId,
                        enabled: data[i].enabled,
                        gpPercent: data[i].gpPercent,
                        id: data[i].id,
                        name: data[i].name,
                        orderIndex: data[i].orderIndex,
                        typeGroup: data[i].typeGroup,
                        img: util.getDeliveryImg(data[i].id),
                        isEdit: false
                    });
                }
                this.salesChannel = detail
            });
        },
        addImageLabels() {
            const chart = this.$refs.chart.$_chart; // Reference the Chart.js instance
            const ctx = chart.ctx;
            const xAxis = chart.scales.x; // Access the x-axis scale
            const icons = this.chartOptions.icons; // Get the icons array

            // Loop through each x-axis tick and draw the corresponding icon
            xAxis.ticks.forEach((tick, index) => {
                const img = new Image();
                img.src = icons[index]; // Use the icon for this label
                img.onload = () => {
                const x = xAxis.getPixelForTick(index) - img.width / 2; // Center the icon horizontally
                const y = chart.height - 50; // Adjust y-position of the icon
                ctx.drawImage(img, x, y, 30, 30); // Draw icon with size 30x30
                };
            });
        },
    },
}
</script>
<style>
.delivery-chart {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
}

.legend {
    width: 250px;
}

.legend ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.legend li {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.color-box {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    display: inline-block;
}
</style>